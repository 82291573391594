import { Avatar, Button, Grid, Typography, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import SoundSystemImage from "assets/images/SoundSystemImage.png";
import GirlWithHeart from "assets/images/GirlWithHeart.png";
import burstPuckerSvg from 'assets/svg/Burst-pucker-2.svg'
import { ReactComponent as Logo } from 'assets/svg/Logo.svg'
import { DarkButtonWithIcon } from "components/controls/Buttons";
import { UnauthenticatedPath } from "utility/AppRoutingPath";
import { useLocation, useNavigate } from "react-router-dom";

type Props = {};

const MadeForCreators = (props: Props) => {
  const responsiveImageSize = [150, 100, 150, 260, 280];

  const AboveSMBreakpoint = useMediaQuery((theme: any) => theme.breakpoints.up('md'));
  const BelowSMBreakpoint = useMediaQuery((theme: any) => theme.breakpoints.down('sm')) as boolean;

  const nav = useNavigate()
  const location = useLocation()

  const handleClick = () => {
    if (location.pathname === UnauthenticatedPath.Aboutus) {
      nav(UnauthenticatedPath.Contactus)
    } else {
      nav(UnauthenticatedPath.Aboutus)
    }
  }

  return (
    <Grid container justifyContent="center" alignItems="center" gap={4} color="white" position="relative">
      <Grid>
        <img
          src={SoundSystemImage}
          style={{ width: '100%', height: '100%', objectFit: 'cover' }}
          alt=""
        /></Grid>
      <Grid
        p={['1rem', '2rem', '4rem', '5rem']}
        position="absolute"
        direction="row-reverse"
        item
        xs={12}
        container
        alignItems="flex-end"
        justifyContent="space-between"
        top={0}
        left={0}
        right={0}
        bottom={0}
      >
        <Grid item xs={10} sm={7} md={3} lg={3} textAlign="right" pr={['1rem', 0]}>
          <Typography
            fontWeight={700}
            sx={{
              fontSize: ['1.2rem', '2.5rem', '2rem', '3rem', '4.7129rem'],
              fontFamily: "'Darker Grotesque', sans-serif",
              lineHeight: '0.8',
              mb: '0.2rem',
            }}
          >
            MADE FOR
            <br />
            <span
              style={{
                color: '#FA6B05',
                fontFamily: "'Darker Grotesque', sans-serif",
              }}
            >
              CREATORS
            </span>
          </Typography>
          <Typography mb="0.5rem" variant="subtitle2" sx={{ display: BelowSMBreakpoint ? 'none' : 'block' }}>
            A platform built for Artists by Artists
          </Typography>
          <DarkButtonWithIcon
            sx={{
              width: 'auto', // Adjust the width as needed
              height: 'auto', // Adjust the height as needed
              padding: '0.2rem 0.7rem', // Adjust padding for the button's size
              fontSize: '0.9rem', // Adjust font size
            }}
            onClick={handleClick}
          >
            LEARN MORE
          </DarkButtonWithIcon>
        </Grid>
        {!BelowSMBreakpoint && (
          <Grid
            pl={['1rem', 0]}
            item
            xs={2}
            sm={5}
            md={1}
            lg={1}
            gap="10px"
            direction="column"
            container
            alignItems="start"
            justifyContent="flex-start"
          >
            <Logo color="#FA6B05" />
            <Logo color="#3276FB" />
            <Logo color="#FFFFFF" />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default MadeForCreators;

import { Box, Grid } from "@mui/material";
import WaveBackgroundLayout from "components/layout/WaveBackgroundLayout";
import BackdropLoading from "components/other/BackdropLoading";
import ProfileDescription from "components/other/ProfileDescription";
import ProfileHero from "components/other/ProfileHero";
import ProfileListWrapper from "components/other/ProfileListWrapper";
import ProfileMiscellaneousView from "components/other/ProfileMiscellaneousView";
import { AuthContext } from "context/AuthContext";
import React from "react";


import statisCover1 from "assets/images/Bhish.jpg";
import statisCover2 from "assets/images/Ray.png";
import statisCover3 from "assets/images/Shobz.jpg";
import statisCover4 from "assets/images/VibeBuilder.png";
import Artboard1 from "assets/images/recommendation place holder/Artboard 1.png";
import Artboard2 from "assets/images/recommendation place holder/Artboard 2.png";
import Artboard3 from "assets/images/recommendation place holder/Artboard 3.png";
import Artboard4 from "assets/images/recommendation place holder/Artboard 4.png";
import Artboard5 from "assets/images/recommendation place holder/Artboard 5.png";
import Artboard6 from "assets/images/recommendation place holder/Artboard 6.png";
import Artboard7 from "assets/images/recommendation place holder/Artboard 7.png";
import Artboard8 from "assets/images/recommendation place holder/Artboard 8.png";
//import PurchasedTracksListings from "components/other/PurchasedTracksListings";

type Props = {};

const followedArtistData = [
  {
    title: "FOLLOWED ARTISTS - Coming Soon",
    data: [
      {
        name: "Artist 1",
        image: Artboard1,
      },
      {
        name: "Artist 2",
        image: Artboard2,
      },
      {
        name: "Artist 3",
        image: Artboard3,
      },
      {
        name: "Artist 4",
        image: Artboard4,
      },
      {
        name: "Artist 5",
        image: Artboard5,
      },
      {
        name: "Artist 6",
        image: Artboard6,
      },
      {
        name: "Artist 7",
        image: Artboard7,
      },
      {
        name: "Artist 8",
        image: Artboard8,
      },
    ],
  }
]
const recommendationData = [
  {
    title: "RECOMMENDATIONS - Coming Soon",
    data: [
      {
        name: "Artist 1",
        image: Artboard1,
      },
      {
        name: "Artist 2",
        image: Artboard2,
      },
      {
        name: "Artist 3",
        image: Artboard3,
      },
      {
        name: "Artist 4",
        image: Artboard4,
      },
      {
        name: "Artist 5",
        image: Artboard5,
      },
      {
        name: "Artist 6",
        image: Artboard6,
      },
      {
        name: "Artist 7",
        image: Artboard7,
      },
      {
        name: "Artist 8",
        image: Artboard8,
      },
    ],
  }
]

const UserProfile = (props: Props) => {

  const tabsListingData = [
    {
      viewName: "LIKED SONGS",
      view: <>Coming Soon</>
    },
    // {
    //   viewName: "PURCHASED TRACKS",
    //   view: <PurchasedTracksListings/>
    // },
    {
      viewName: "EVENTS",
      view: <>Coming Soon</>
    }
  ]

  return (
    <WaveBackgroundLayout oneWave={false} twoWave={false}>
      <Grid container color='white' p={["1rem", "2rem", "4rem", "5rem"]} pt={["7rem", "6rem", null, null, null]}>
        <Grid height='fit-content' item container xs={12} sm={12} md={7} lg={7}><ProfileHero /></Grid>
        <Grid mt={['1rem', '1rem']} px={[0, 0, 4]} height='fit-content' item container xs={12} sm={12} md={5} lg={5} order={[3,3,2]}><ProfileMiscellaneousView statisData={followedArtistData} /></Grid>
        <Grid mt={['1rem', '1rem','0rem']} height='fit-content' item container xs={12} sm={12} md={7} lg={7} order={[2,2,3]}><ProfileListWrapper tabs={tabsListingData} /></Grid>
        <Grid mt={['1rem', '1rem','0rem']} px={[0, 0, 4]} height='fit-content' item container xs={12} sm={12} md={5} lg={5} order={4}><ProfileMiscellaneousView statisData={recommendationData} /></Grid>
      </Grid>
    </WaveBackgroundLayout>
  );
};

export default UserProfile;

import React, { useState, useEffect, useContext } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Avatar,
  Typography,
  IconButton,
  CircularProgress,
  Box,
  TableContainer,
  TableHead,
} from "@mui/material";
import HttpTransferService from "services/httptransfer";
import { styled } from "@mui/material/styles";
import PlayCircle from "@mui/icons-material/PlayCircle";
import PauseCircle from "@mui/icons-material/PauseCircle";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import DownloadIcon from "@mui/icons-material/Download";
import { MusicPlayerContext } from "context/MusicPlayerContext";
import { MusicStoreContext } from "context/MusicStoreContext";
import { AuthContext } from "context/AuthContext";

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  border: "1px solid #636B82",
  borderRadius: "16px",
  background: "transparent",
  "& .MuiTableCell-root": {
    borderBottom: "1px solid #636B82",
    color: "#fff",
  },
  position: "relative",
}));

const StyledTableHeader = styled(TableHead)(({ theme }) => ({
  background:
    "linear-gradient(134.34deg, rgb(30 35 50 / 69%) 0%, rgb(12 13 19) 100%)",
  backdropFilter: "blur(100px)",
  color: "#fff",
  position: "sticky",
  top: 0,
  zIndex: 2,
}));

interface MusicCover {
  file_download_url: string;
}
interface AudioItem {
  file_download_url: string;
}

interface IMusic {
  album_id: null;
  id: string;
  _id: string;
  name: string;
  artist: string;
  genre: string;
  music_mp3_audio_file: AudioItem;
  music_cover_image: MusicCover;
  user_id: string;
  genres: [string];
  artist_collaborators_name: string[];
  label_collaborators_name: string[];
  price: number;
}

interface IPagination {
  page_no: number;
  page_size: number;
  total_pages: number;
  total_records: number;
}

const httptransfer = new HttpTransferService();

const MusicTable: React.FC = () => {
  const [musicList, setMusicList] = useState<IMusic[]>([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState<IPagination>({
    page_no: 1,
    page_size: 10,
    total_pages: 0,
    total_records: 0,
  });
  const [currentPlayingId, setCurrentPlayingId] = useState<string | null>(null);
  const [hoveredCoverId, setHoveredCoverId] = useState<string | null>(null);

  const musicPlayerContext = useContext(MusicPlayerContext);
  const musicStoreContext = useContext(MusicStoreContext);
  const authcontext = React.useContext(AuthContext)!;

  const handlePlay = (track: IMusic) => {
    if (musicPlayerContext) {
      if (currentPlayingId === track._id) {
        musicPlayerContext.TogglePlay();
      } else {
        musicPlayerContext.Pause();
        setCurrentPlayingId(null);

        const successCallback = (res: any) => {
          const trackToGiveToPlayer = {
            id: track._id,
            trackAudioUrl:
              res.data.musics[0].music_mp3_audio_file.file_download_url,
            trackImageUrl: track.music_cover_image.file_download_url,
            trackName: track.name,
          };
          musicPlayerContext.openPlayer(trackToGiveToPlayer, musicList);
          setCurrentPlayingId(track._id);
        };

        const errorCallback = (err: any) => {
          console.error("Failed to fetch music:", err);
        };

        const inputJson = {
          mp3_play: true,
          music_id: [track._id],
          user_id: [track.user_id],
        };

        httptransfer.musicQuery(inputJson, successCallback, errorCallback);
      }
    }
  };

  const handleLike = (trackId: string) => {
    // Implement like functionality
  };
  const handleAddToCart = (track: IMusic) => {
    if (musicStoreContext) {
      musicStoreContext.addToCart({
        music_id: track._id,
        album_id: track.album_id || "",
      });
    }
  };

  useEffect(() => {
    fetchMusic(1);
  }, []);

  const fetchMusic = (page: number) => {
    setLoading(true);
    const inputJson = {
      sorting_details: {
        sort_by: "create_date",
        sort_order: "DSC",
      },
      pagination_details: {
        page_number: page,
        page_size: pagination.page_size,
      },
    };

    httptransfer.musicQuery(
      inputJson,
      response => {
        setMusicList(prevList => [...prevList, ...response.data.musics]);
        setPagination(prev => ({
          ...prev,
          page_no: prev.page_no + 1,
          total_pages: response.data.pagination_details.total_pages,
          total_records: response.data.pagination_details.total_records,
        }));
        setLoading(false);
      },
      error => {
        console.error("Failed to fetch music:", error);
        setLoading(false);
      }
    );
  };

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const { scrollTop, scrollHeight, clientHeight } = event.currentTarget;
    const bottomTolerance = 50;

    if (
      scrollHeight - (scrollTop + clientHeight) <= bottomTolerance &&
      !loading
    ) {
      fetchMusic(pagination.page_no);
    }
  };

  const handleInnerScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const target = event.currentTarget;

    if (
      target.scrollTop !== 0 &&
      target.scrollTop !== target.scrollHeight - target.clientHeight
    ) {
      event.stopPropagation();
    }
  };

  return (
    <StyledTableContainer
      sx={{
        maxHeight: 540,
        overflow: "auto",
        scrollbarWidth: "none",
        "&::-webkit-scrollbar": { display: "none" },
      }}
      onScroll={event => {
        handleScroll(event as React.UIEvent<HTMLDivElement>);
        handleInnerScroll(event as React.UIEvent<HTMLDivElement>);
      }}
    >
      <Table aria-label="simple table">
        <StyledTableHeader>
          <TableRow>
            <TableCell>Track Name</TableCell>
            <TableCell align="right">Artist</TableCell>
            <TableCell align="right">Genre</TableCell>
            <TableCell align="right">Label</TableCell>
            <TableCell align="right">Price</TableCell>
            <TableCell align="right">Actions</TableCell>
          </TableRow>
        </StyledTableHeader>
        <TableBody>
          {musicList.map(music => (
            <TableRow
              key={music.id}
              sx={{
                cursor: "pointer",
                "&:hover": {
                  backgroundColor: "#636b825e",
                },
                backgroundColor:
                  musicPlayerContext?.currentTrack?.id === music._id
                    ? "rgb(250, 107, 5)"
                    : "transparent",
              }}
            >
              <TableCell
                component="th"
                scope="row"
                sx={{ padding: "9.5px 20px" }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <div
                    style={{ position: "relative" }}
                    onMouseEnter={() => setHoveredCoverId(music._id)}
                    onMouseLeave={() => setHoveredCoverId(null)}
                  >
                    <Avatar
                      src={music.music_cover_image?.file_download_url}
                      sx={{
                        width: "60px",
                        height: "60px",
                        borderRadius: "4px",
                        marginRight: 2,
                        cursor: "pointer",
                        position: "relative",
                      }}
                      // onClick={() => handlePlay(music)}
                    />
                    {hoveredCoverId === music._id && (
                      <Box
                        onClick={() => handlePlay(music)}
                        sx={{
                          width: "60px",
                          height: "60px",
                          borderRadius: "4px",
                          backgroundColor: "#636b825e",
                          opacity: "1",
                          position: "absolute",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          top: 0,
                          left: 0,
                          transition: "opacity 0.2s ease-in-out",
                        }}
                      >
                        {musicPlayerContext?.currentTrack?.id === music._id &&
                        musicPlayerContext?.IsPlaying ? (
                          <PauseCircle
                          // onClick={musicPlayerContext?.TogglePlay}
                          />
                        ) : (
                          <PlayCircle
                          // onClick={musicPlayerContext?.TogglePlay}
                          />
                        )}
                      </Box>
                    )}
                  </div>
                  <Typography>{music.name}</Typography>
                </Box>
              </TableCell>
              <TableCell align="right">
                {music.artist_collaborators_name.join(", ")}
              </TableCell>
              <TableCell align="right">
                {music.genres.map(genre => genre).join(", ")}
              </TableCell>
              <TableCell align="right">
                {music.label_collaborators_name.join(", ")}
              </TableCell>
              <TableCell align="right">
                {music.price ? `$${music.price.toFixed(2)}` : "N/A"}
              </TableCell>
              <TableCell align="right">
                {music.price === 0 ? (
                  <IconButton
                    onClick={() => {
                      window.open(
                        music.music_mp3_audio_file.file_download_url,
                        "_blank"
                      );
                    }}
                  >
                    <DownloadIcon sx={{ color: "#fff" }} />
                  </IconButton>
                ) : (
                  <IconButton onClick={() => handleAddToCart(music)}>
                    <ShoppingCartIcon sx={{ color: "#fff" }} />
                  </IconButton>
                )}
              </TableCell>
            </TableRow>
          ))}
          {loading && (
            <TableRow>
              <TableCell colSpan={6} style={{ textAlign: "center" }}>
                <CircularProgress />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </StyledTableContainer>
  );
};

export default MusicTable;

import {
  Avatar,
  CircularProgress,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  ListItem,
  ListItemText,
  Divider,
  Button,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { ReactComponent as EditIcon } from "assets/svg/edit.svg";
import { ReactComponent as CloseSquare } from "assets/svg/closeSquare.svg";
import { ReactComponent as Download } from "assets/svg/Download.svg";
import { AuthContext } from "context/AuthContext";
import DialogPopup from "components/layout/DialogPopup";
import EditMusicForm from "./EditAlbumForm";
import { MusicPlayerContext } from "context/MusicPlayerContext";
import EditSingleMusicForm from "./EditSingleMusicForm";
import { PauseCircle, PlayCircle, Album } from "@mui/icons-material";
import { TrackUploadContext } from "context/TrackUploadContext";
import { MusicStoreContext } from "context/MusicStoreContext";
import { LightBlurButtonWithCustomIcon } from "components/controls/Buttons";
import { ReactComponent as AddSquare } from "assets/svg/add-square.svg";
import UploadMusicForm from "./UploadMusicForm";
import HttpTransferService from "services/httptransfer";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { ReactComponent as Seperator } from "assets/svg/Seperator.svg";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { PlayListContext } from "context/PlaylistContext";
import redHeart from "assets/images/heart.png";
import emptyHeart from "assets/images/emptyHeart.png";
import FilterMusic from "assets/images/musicFilter.png";
import MoreSquare from "assets/images/moreSquare.png";
import { ReactComponent as ShoppingCart } from "assets/svg/shopping-cart.svg";
import PopperComponents from "components/customisedComponents/PopperComponents";
import { AlertContext } from "context/AlertContext";
import { severityType } from "model/errorSeverityTypeEnum";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { ReactComponent as GrayPlaylist } from "assets/svg/grayPlaylist.svg";

type Props = {
  visible: any;
  albumId: any;
  labelData: any;
};
interface Song {
  _id: string;
  album_id: string | null;
}

interface Album {
  _id: string;
  musics: string[];
}

const httptransfer = new HttpTransferService();

const organizeSongsIntoAlbums = (songs: Song[], albumData: Album[]): any[] => {
  const albums: Record<string, { album_id: string | null; songs: Song[] }> = {};
  const musicOrder: { [albumId: string]: string[] } = {};

  albumData.forEach(album => {
    musicOrder[album._id] = album.musics;
  });

  songs.forEach((song: Song) => {
    if (song.album_id !== null) {
      // If the song has an album_id, group it under the corresponding album
      if (!albums[song.album_id]) {
        albums[song.album_id] = { album_id: song.album_id, songs: [] };
      }
      // Check if the song is not already in the album's songs array
      if (!albums[song.album_id].songs.some(s => s._id === song._id)) {
        const musicIdsInAlbum = musicOrder[song.album_id];
        const musicIndex = musicIdsInAlbum?.indexOf(song._id);
        if (musicIndex !== -1) {
          // Insert the song at the specified index based on music order
          albums[song.album_id].songs.splice(musicIndex, 0, song);
        } else {
          // If the music ID is not found in the order, push the song to the end
          albums[song.album_id].songs.push(song);
        }
      }
    } else {
      // If the song has no album_id, create an individual album for it
      const individualAlbumId = `individual_${song._id}`;
      if (!albums[individualAlbumId]) {
        albums[individualAlbumId] = { album_id: null, songs: [] };
      }
      // Check if the song is not already in the album's songs array
      if (!albums[individualAlbumId].songs.some(s => s._id === song._id)) {
        albums[individualAlbumId].songs.push(song);
      }
    }
  });
  const organizedSongsInAlbums = Object.values(albums);
  return organizedSongsInAlbums;
};

const ManageSongListing = (props: Props) => {
  const [editAlbumDialogFlag, seteditAlbumDialogFlag] = useState(false);
  const [editAlbumFormData, seteditAlbumFormData] = useState<any>({});

  const [editSongDialogFlag, seteditSongDialogFlag] = useState(false);
  const [editSongFormData, seteditSongFormData] = useState<any>({});
  const [itemData, setItemData] = useState<any>({});
  const [dialogMusicOpen, setDialogMusicOpen] = useState(false);
  const [hoveredSongId, setHoveredSongId] = useState<string | null>(null);
  const [hoveredDragSongId, setHoveredDragSongId] = useState<string | null>(
    null
  );
  const [isHovered, setIsHovered] = useState(false);
  const [isSong, setIsSong] = useState(false);
  const [unsavedChanges, setUnsavedChanges] = React.useState(false);
  const [disableIconButton, setDisableIconButton] = React.useState(false);
  const [currentPlayingItemId, setCurrentPlayingItemId] = useState<
    string | null
  >(null);
  const [uniqueItemId, setUniqueItemId] = useState<string | null>(null);
  const [specificItemID, setSpecificItemID] = useState<any>({});
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [openPopper, setOpenPopper] = React.useState(false);
  const [openMoreOption, setOpenMoreOption] = React.useState(false);
  const [isSuccessOpen, setSuccessOpen] = useState(false);

  const musicPlayerContext = React.useContext(MusicPlayerContext);
  const alertContext = React.useContext(AlertContext);
  const playListContext = React.useContext(PlayListContext);
  const musicStoreContext = React.useContext(MusicStoreContext);
  const AlbumsContext = React.useContext(TrackUploadContext);
  const authcontext = React.useContext(AuthContext)!;

  const userInfoString = localStorage.getItem("userInfo");
  const userInfo = userInfoString ? JSON.parse(userInfoString) : null;
  const theme = useTheme();
  const phoneScreenBreakpoint = useMediaQuery(theme.breakpoints.down("sm"));

  const styleForSvg = {
    width: phoneScreenBreakpoint ? "20px" : "22px",
    height: phoneScreenBreakpoint ? "20px" : "22px",
  };

  const handleOpenMusicStore = (
    event: React.MouseEvent<HTMLElement>,
    params: any,
    item: any
  ) => {
    if (anchorEl == event.currentTarget) {
      setOpenPopper(!openPopper);
    } else {
      setAnchorEl(event.currentTarget);
      setItemData(item);
      setUniqueItemId(item._id);
      setOpenPopper(true);
      setSpecificItemID(params);
      console.log("event.currentTarget", anchorEl);
    }
  };

  const handleEditAndDeleteOpen = (
    event: React.MouseEvent<HTMLElement>,
    item: any,
    isSong: boolean
  ) => {
    setAnchorEl(event.currentTarget);
    setItemData(item);
    setUniqueItemId(item._id);
    setOpenMoreOption(true);
    setIsSong(isSong);
  };

  const handleMouseEnter = (songId: string) => {
    setHoveredSongId(songId);
  };

  const handleShowDrag = (songId: string) => {
    setIsHovered(true);
    setHoveredDragSongId(songId);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
    setHoveredSongId(null);
  };

  const handleDragLeave = () => {
    setHoveredDragSongId(null);
  };

  const openEditAlbumDialog = () => {
    seteditAlbumDialogFlag(true);
  };

  const closeEditAlbumDialog = () => {
    seteditAlbumDialogFlag(false);
  };

  const openEditSongDialog = () => {
    seteditSongDialogFlag(true);
  };

  const closeEditSongDialog = () => {
    seteditSongDialogFlag(false);
  };

  const openMusicDialog = () => {
    setDialogMusicOpen(true);
  };

  const closeMusicDialog = () => {
    setDialogMusicOpen(false);
  };

  const deleteAlbumHandler = (id: string) => {
    httptransfer.deleteAlbum(id, successCallback, errorCallback);
  };

  const successCallback = (res: any) => {
    AlbumsContext?.GetAllAlbumsByUser();
  };

  const errorCallback = (err: any) => {
    console.log("err", err);
  };

  const deleteSongHandler = (id: string) => {
    const successCallback = (res: any) => {
      AlbumsContext?.GetAllAlbumsByUser();
    };

    const errorCallback = (err: any) => {
      console.log("err", err);
    };

    httptransfer.deleteMusic(id, successCallback, errorCallback);
  };

  const likeHandler = (params: object) => {
    const successCallback = (res: any) => {
      playListContext?.GetAllPlayList();
    };
    const errorCallback = (err: any) => {
      console.log("err", err);
    };
    if (localStorage.getItem("APP_GATEWAY_ACCESS_TOKEN")) {
      httptransfer.addInPlaylist(
        AlbumsContext?.DefaultPlayListId
          ? AlbumsContext?.DefaultPlayListId
          : null,
        params,
        successCallback,
        errorCallback
      );
    } else {
      showConfirmationDialog("like");
    }
  };

  const unLikeHandler = (musicId: string) => {
    const playlistMusicId = playListContext?.LikedPlaylistData?.musics.find(
      (music: any) => music.music_id === musicId
    )?.playlist_music_id;
    const successCallback = (res: any) => {
      //AlbumsContext?.GetAllAlbumsByUser();
      playListContext?.GetAllPlayList();
    };
    const errorCallback = (err: any) => {
      console.log("err", err);
    };
    if (localStorage.getItem("APP_GATEWAY_ACCESS_TOKEN")) {
      httptransfer.deleteSongFromPlaylist(
        AlbumsContext?.DefaultPlayListId,
        playlistMusicId,
        successCallback,
        errorCallback
      );
    } else {
      showConfirmationDialog("like");
    }
  };

  const organizedAlbums = organizeSongsIntoAlbums(
    AlbumsContext?.Musics?.musicData || [],
    AlbumsContext?.Albums?.albumData || []
  );
  const allSongs = organizedAlbums.reduce((songs, album) => {
    return songs.concat(album.songs);
  }, []);

  const handleSelected = (item: any) => {
    if (currentPlayingItemId === item._id) {
      // If the clicked item is the currently playing one, toggle play/pause
      if (musicPlayerContext) {
        if (musicPlayerContext.IsPlaying) {
          musicPlayerContext.Pause();
        } else {
          musicPlayerContext.Play();
        }
      }
    } else {
      // If music is stopped or not started, start playing the selected song
      const successCallback = (res: any) => {
        const trackToGiveToPlayer = {
          id: item._id,
          trackAudioUrl:
            res.data.musics[0].music_mp3_audio_file.file_download_url,
          trackImageUrl: item.music_cover_image.file_download_url,
          trackName: item.name,
        };
        musicPlayerContext!.openPlayer(trackToGiveToPlayer, allSongs);
        setCurrentPlayingItemId(item._id);
      };

      const errorCallback = (err: any) => {
        console.log("err", err);
      };

      if (localStorage.getItem("APP_GATEWAY_ACCESS_TOKEN")) {
        const inputJson = {
          user_id: authcontext?.users
            ? [authcontext?.users._id]
            : [userInfo._id],
          mp3_play: true,
          music_id: [item._id],
        };

        httptransfer.musicQuery(inputJson, successCallback, errorCallback);
      } else {
        showConfirmationDialog("play");
      }
    }
  };

  const showConfirmationDialog = (condition: string) => {
    window.ShowInfoDialog(
      `Please login from the top right corner to ${condition}`
    );
  };

  const [expandedAlbumId, setExpandedAlbumId] = useState<string | false>(false);

  const handleAccordionChange =
    (albumId: string) =>
    (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
      setExpandedAlbumId(isExpanded ? albumId : false);
    };

  const album = AlbumsContext?.Albums.albumData.find(
    (item: any) => item._id === props.albumId
  );
  let musicItems: any;
  if (album && album.musics.length > 0) {
    const allMusicItemsWithSameAlbumId = AlbumsContext?.Musics.musicData.filter(
      (song: any) => song.album_id === props.albumId
    );
    const orderedMusicItems = album.musics
      .map((musicId: any) =>
        AlbumsContext?.Musics.musicData.find(
          (song: any) => song._id === musicId
        )
      )
      .filter((music: any) => music);

    const remainingMusicItems = allMusicItemsWithSameAlbumId.filter(
      (song: any) => !album.musics.includes(song._id)
    );

    musicItems = orderedMusicItems.concat(remainingMusicItems);
  } else {
    musicItems = AlbumsContext?.Musics.musicData.filter(
      (song: any) => song.album_id === props.albumId
    );
  }

  const onDragEnd = (result: any, albumData: any) => {
    if (!result.destination) return;
    const reorderedMusicItems = [...musicItems];
    const [reorderedItem] = reorderedMusicItems.splice(result.source.index, 1);
    reorderedMusicItems.splice(result.destination.index, 0, reorderedItem);
    musicItems = reorderedMusicItems;
    const songsIds = reorderedMusicItems.map(song => song._id);
    const {
      created_by,
      updated_by,
      create_date,
      album_cover_image,
      update_date,
      __v,
      _id,
      ...updatedAlbumData
    } = albumData;
    updatedAlbumData.musics = songsIds;
    const successCallback = (res: any) => {
      musicItems = reorderedMusicItems;
      AlbumsContext?.GetAllAlbumsByUser();
    };

    const errorCallback = (err: any) => {
      console.log("err", err);
    };
    httptransfer.updateAlbum(
      albumData._id,
      updatedAlbumData,
      successCallback,
      errorCallback
    );
  };

  const isSongLiked = (musicId: any) => {
    if (playListContext?.PlayListData) {
      const likedPlaylist = playListContext.PlayListData.find(
        (playlist: any) => playlist.type === "LIKED_MUSIC_PLAYLIST"
      );
      if (likedPlaylist && likedPlaylist.musics) {
        return likedPlaylist.musics.some(
          (music: any) => music.music_id === musicId
        );
      }
    }
    return false;
  };

  const isSongInPlaylist = (musicId: any) => {
    for (const playlist of playListContext?.PlayListData) {
      if (playlist.type !== "LIKED_MUSIC_PLAYLIST") {
        const isInPlaylist = playlist.musics.some(
          (music: any) => music.music_id === musicId
        );

        if (isInPlaylist) {
          return true;
        }
      }
    }
    return false;
  };

  const areAllSongsLiked = (songs: any) => {
    if (songs?.length > 0) {
      return songs.every((songId: any) => isSongLiked(songId._id));
    }
  };

  const unlikeAllSongsInAlbum = (songs: any) => {
    songs.forEach((song: any) => {
      unLikeHandler(song._id);
    });
  };

  const isSongInPublicPlaylist = (songs: any[]) => {
    if (!Array.isArray(songs) || songs.length === 0) {
      return false;
    }

    for (const song of songs) {
      if (song.visibility === "PUBLIC") {
        for (const playlist of playListContext?.PlayListData) {
          if (playlist.type !== "LIKED_MUSIC_PLAYLIST" && playlist.musics) {
            if (
              playlist.musics.some((music: any) => music.music_id === song._id)
            ) {
              return true;
            }
          }
        }
      }
    }
    return false;
  };

  const handleAddToCart = (params: any) => {
    musicStoreContext?.addToCart({
      music_id: params.music_id,
      album_id: params.album_id || "",
    });
  };

  const playlistCardJsx = (item: any, params: any) => {
    const tabStyle = {
      cursor: "pointer",
      padding: "10px",
      transition: "background-color 0.3s",
    };
    const listItemStyle = {
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "gray !important",
      },
    };

    const handleCreatePlaylist = (item: any) => {
      const addMusicsuccessCallback = () => {
        setSuccessOpen(true);
        playListContext?.GetAllPlayList();
        setOpenPopper(false);
      };

      const errorCallback = (error: any) => {
        alertContext?.OpenError({
          title: "Playlist Error",
          message: error.response.data.message,
          severity: severityType.error,
        });
        setOpenPopper(false);
      };

      const createsuccessCallback = (res: any) => {
        httptransfer.addInPlaylist(
          res.data.playlist_id,
          params,
          addMusicsuccessCallback,
          errorCallback
        );
      };
      httptransfer.createNewPlaylist(
        { name: item.name },
        createsuccessCallback,
        errorCallback
      );
    };

    const handleAddAlbumOrMusic = (item: any) => {
      const addMusicsuccessCallback = () => {
        playListContext?.GetAllPlayList();
        setOpenPopper(false);
        setSuccessOpen(true);
      };

      const errorCallback = (error: any) => {
        alertContext?.OpenError({
          title: "Playlist Error",
          message: error.response.data.message,
          severity: severityType.error,
        });
        setOpenPopper(false);
      };

      httptransfer.addInPlaylist(
        item._id,
        params,
        addMusicsuccessCallback,
        errorCallback
      );
    };

    return (
      <React.Fragment>
        <Grid container alignItems="center" justifyContent="center">
          <Grid item xs={12} textAlign="left">
            <Button
              variant="text"
              color="inherit"
              onClick={e => {
                e.stopPropagation();
                handleCreatePlaylist(item);
              }}
            >
              <AddSquare style={{ marginRight: "0.4rem" }} /> Create Playlist
            </Button>
            <Divider style={{ backgroundColor: "gray", marginTop: "0.1rem" }} />
          </Grid>

          <Grid item xs={12} textAlign="left">
            <List>
              {AlbumsContext?.PlayListData.map(
                (item: any, index: any) =>
                  item.name &&
                  item.type !== "LIKED_MUSIC_PLAYLIST" && (
                    <React.Fragment key={index}>
                      <ListItem style={listItemStyle}>
                        <ListItemText
                          primary={item.name}
                          onClick={e => {
                            e.stopPropagation();
                            handleAddAlbumOrMusic(item);
                          }}
                        />
                      </ListItem>
                    </React.Fragment>
                  )
              )}
            </List>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  };

  const moreOptionCardJsx = (item: any, isSong: boolean) => {
    return (
      <React.Fragment>
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          display="flex"
        >
          <Grid item xs={12} textAlign="left">
            <Button
              variant="text"
              color="inherit"
              onClick={e => {
                e.stopPropagation();
                if (isSong) {
                  seteditSongFormData(item);
                  openEditSongDialog();
                } else {
                  seteditAlbumFormData(item);
                  openEditAlbumDialog();
                }
                setOpenMoreOption(false);
              }}
            >
              {isSong ? (
                <EditIcon style={{ marginRight: "0.4rem" }} />
              ) : (
                <EditIcon style={{ marginRight: "0.4rem" }} />
              )}{" "}
              Edit
            </Button>
          </Grid>
          <Grid item xs={12} textAlign="left">
            <Button
              variant="text"
              color="inherit"
              onClick={e => {
                e.stopPropagation();
                if (isSong) {
                  window.ShowConfirmationDialog(
                    "Do you want to delete this song?",
                    () => deleteSongHandler(item._id)
                  );
                } else {
                  window.ShowConfirmationDialog(
                    "Do you want to delete this album?",
                    () => deleteAlbumHandler(item._id)
                  );
                }
                setOpenMoreOption(false);
              }}
            >
              {isSong ? (
                <CloseSquare style={{ marginRight: "0.4rem" }} />
              ) : (
                <CloseSquare style={{ marginRight: "0.4rem" }} />
              )}{" "}
              Delete
            </Button>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  };

  const albumDetailsCardJsx = (
    item: any,
    users: any,
    border: boolean,
    song?: any,
    isExpanded?: boolean,
    songs?: any
  ) => {
    const allSongsLiked = areAllSongsLiked(songs);
    const allSongsInPlaylist = isSongInPublicPlaylist(songs);
    //const isAlbumPurchased = musicStoreContext?.purchsedItems.some((data: any) => data.album.includes(item._id));
    return (
      <React.Fragment key={item._id}>
        <Grid
          container
          sx={{
            minWidth: "450px",
            borderBottom: border ? "" : "1px solid #636B82",
            backgroundColor: "black",
            p: ["9px", "9.5px 20px 9.5px 20px"],
            cursor: "pointer",
            "&:hover": {
              backgroundColor: "#ffffff1f",
              "& #play-icon": {
                opacity: "1",
              },
            },
          }}
          justifyContent="space-between"
        >
          <Grid
            item
            container
            xs={9}
            justifyContent="flex-start"
            alignItems={"center"}
            position={"relative"}
          >
            {item.album_cover_image && (
              <>
                <Avatar
                  src={item.album_cover_image.file_download_url}
                  sx={{
                    width: "60px",
                    height: "60px",
                    borderRadius: "4px",
                  }}
                ></Avatar>
                {!props.visible && (
                  <Box
                    onClick={() => handleSelected(song)}
                    id="play-icon"
                    sx={{
                      width: "60px",
                      height: "60px",
                      borderRadius: "4px",
                      backgroundColor: "#636b825e",
                      opacity: "0",
                      position: "absolute",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      [theme.breakpoints.down("sm")]: {
                        position: "absolute",
                        top: 0,
                      },
                    }}
                  >
                    {song?._id && <PlayCircle />}
                  </Box>
                )}
              </>
            )}

            <Grid
              item
              container
              xs={9}
              direction={"column"}
              justifyContent="flex-start"
              alignItems="flex-start"
              sx={{ ml: "10px" }}
              flexWrap="nowrap"
            >
              <Grid
                item
                xs={9}
                container
                gap="0.5rem"
                alignItems="center"
                flexWrap={"nowrap"}
              >
                <Typography
                  noWrap
                  sx={{ fontSize: ["12px", "16px"], fontWeight: "700" }}
                >
                  {item.name}
                </Typography>
                {!props.visible && (
                  <>
                    <Seperator />{" "}
                    <Typography sx={{ fontSize: ["12px", "16px"] }}>
                      {" "}
                      Album{" "}
                    </Typography>
                  </>
                )}
              </Grid>
              <Grid item xs={9} container gap="0.5rem" alignItems="center">
                <Typography sx={{ fontSize: ["12px", "16px"] }}>
                  {item?.artist_collaborators_name?.length > 0 &&
                  item?.artist_collaborators_id?.length > 0
                    ? `${item?.artist_collaborators_name.join(
                        ", "
                      )}, ${item?.artist_collaborators_id
                        ?.map((id: string) => {
                          const artist = users.find(
                            (user: any) => user._id === id
                          );
                          return artist ? artist.name : "";
                        })
                        .join(", ")}`
                    : item?.artist_collaborators_name?.length > 0
                    ? item?.artist_collaborators_name?.join(", ")
                    : (item?.artist_collaborators_id ?? [])
                        .map((id: string) => {
                          const artist = users.find(
                            (user: any) => user._id === id
                          );
                          return artist ? artist.name : "";
                        })
                        .join(", ")}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            //zIndex={3}
            item
            container
            xs={props.visible ? 3 : 2}
            gap="1rem"
            flexWrap={"nowrap"}
            justifyContent="flex-end"
            alignItems="center"
          >
            {!props.visible ? (
              <>
                <Grid item container xs={4}>
                  {isExpanded ? (
                    <KeyboardArrowUpIcon
                      style={{ color: "#ffffff", fontSize: "2rem" }}
                    />
                  ) : (
                    <KeyboardArrowDownIcon
                      style={{ color: "#ffffff", fontSize: "2rem" }}
                    />
                  )}
                </Grid>
                {item.created_by === userInfo?._id &&
                  localStorage.getItem("APP_GATEWAY_ACCESS_TOKEN") && (
                    <Grid item container xs={4}>
                      <ShoppingCart
                        style={styleForSvg}
                        onClick={e => {
                          e.stopPropagation();
                          handleAddToCart({ album_id: item._id });
                        }}
                      />
                    </Grid>
                  )}
                <Grid item container xs={4}>
                  {allSongsLiked ? (
                    <img
                      src={redHeart}
                      style={styleForSvg}
                      onClick={e => {
                        e.stopPropagation();
                        unlikeAllSongsInAlbum(songs);
                      }}
                    />
                  ) : (
                    <img
                      src={emptyHeart}
                      style={styleForSvg}
                      onClick={e => {
                        e.stopPropagation();
                        likeHandler({ album_id: item._id });
                      }}
                    />
                  )}
                </Grid>
                {item.created_by === userInfo?._id &&
                  localStorage.getItem("APP_GATEWAY_ACCESS_TOKEN") && (
                    <>
                      <Grid item container xs={4}>
                        {allSongsInPlaylist ? (
                          <GrayPlaylist style={styleForSvg} />
                        ) : (
                          <img
                            src={FilterMusic}
                            style={styleForSvg}
                            onClick={e => {
                              e.stopPropagation();
                              handleOpenMusicStore(
                                e,
                                { album_id: item._id },
                                item
                              );
                            }}
                          />
                        )}
                      </Grid>
                      <Grid item container xs={4}>
                        <img
                          src={MoreSquare}
                          style={styleForSvg}
                          onClick={e => {
                            e.stopPropagation();
                            handleEditAndDeleteOpen(e, item, false);
                          }}
                        />
                      </Grid>
                    </>
                  )}
              </>
            ) : (
              <Grid container>
                <LightBlurButtonWithCustomIcon
                  onClick={openMusicDialog}
                  CustomIcon={AddSquare}
                >
                  ADD SONG
                </LightBlurButtonWithCustomIcon>
              </Grid>
            )}
          </Grid>
        </Grid>
      </React.Fragment>
    );
  };

  const songDetailsCardJsx = (
    item: any,
    users: any,
    border: boolean,
    seperatorShow?: boolean,
    uniqueId?: string
  ) => {
    const isLiked = isSongLiked(item._id);
    const isInAnyOtherPlaylist = isSongInPlaylist(item._id);
    const isSingleSongAlbum = musicItems.length === 1;
    // const isMusicPurchased = musicStoreContext?.purchsedItems.some((data: any) => {
    //   return (
    //     data.album.includes(item.album_id) ||
    //     data.music.includes(item._id)
    //   );
    // });
    return (
      <React.Fragment>
        <Grid
          container
          onMouseEnter={() => handleShowDrag(item._id)}
          onMouseLeave={handleDragLeave}
          sx={{
            minWidth: "450px",
            borderBottom: border ? "" : "1px solid #636B82",
            backgroundColor: "black",
            p: ["9px", "9.5px 20px 9.5px 20px"],
            cursor: "pointer",
            "&:hover": {
              backgroundColor: "#ffffff1f",
            },
          }}
          justifyContent="space-between"
        >
          <Grid
            item
            container
            xs={10}
            justifyContent="flex-start"
            alignItems={"center"}
          >
            {props.visible &&
              isHovered &&
              hoveredDragSongId === item._id &&
              !isSingleSongAlbum && <DragIndicatorIcon />}
            {item.music_cover_image && (
              <>
                <div
                  {...(!props.visible && {
                    onMouseEnter: () => handleMouseEnter(item._id),
                    onMouseLeave: handleMouseLeave,
                  })}
                  style={{ position: "relative" }}
                >
                  <Avatar
                    src={item.music_cover_image.file_download_url}
                    sx={{
                      width: "60px",
                      height: "60px",
                      borderRadius: "4px",
                      position: "relative",
                    }}
                  ></Avatar>
                  {hoveredSongId === item._id && !props.visible && (
                    <Box
                      onClick={() => handleSelected(item)}
                      id="play-icon"
                      sx={{
                        width: "60px",
                        height: "60px",
                        borderRadius: "4px",
                        backgroundColor: "#636b825e",
                        opacity: "1",
                        position: "absolute",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        [theme.breakpoints.down("sm")]: {
                          position: "absolute",
                          top: 0,
                        },
                        top: 0,
                        left: 0,
                        transition: "opacity 0.2s ease-in-out",
                      }}
                    >
                      {musicPlayerContext?.IsPlaying &&
                      musicPlayerContext?.currentTrack?.id == item._id ? (
                        <PauseCircle onClick={musicPlayerContext?.TogglePlay} />
                      ) : (
                        <PlayCircle onClick={musicPlayerContext?.TogglePlay} />
                      )}
                    </Box>
                  )}
                </div>
              </>
            )}
            <Grid
              item
              container
              xs={8}
              direction={"column"}
              justifyContent="flex-start"
              alignItems="flex-start"
              flexWrap="nowrap"
            >
              <Grid
                item
                xs={12}
                container
                gap="0.5rem"
                alignItems="center"
                flexWrap={"nowrap"}
                sx={{ ml: "10px" }}
              >
                <Typography
                  sx={{
                    fontSize: ["12px", "16px"],
                    fontWeight: "700",
                    alignItems: "center",
                  }}
                >
                  {item.name}
                </Typography>
                {!props.visible && seperatorShow ? (
                  <>
                    <Seperator />{" "}
                    <Typography sx={{ fontSize: ["12px", "16px"] }}>
                      {" "}
                      Song{" "}
                    </Typography>
                  </>
                ) : (
                  ""
                )}
              </Grid>
              <Grid
                item
                xs={12}
                container
                gap="0.5rem"
                alignItems="center"
                sx={{ ml: "10px" }}
              >
                <Typography sx={{ fontSize: ["12px", "16px"] }}>
                  {item?.artist_collaborators_name?.length > 0 &&
                  item?.artist_collaborators_id?.length > 0
                    ? `${item?.artist_collaborators_name.join(
                        ", "
                      )}, ${item?.artist_collaborators_id
                        ?.map((id: string) => {
                          const artist = users.find(
                            (user: any) => user._id === id
                          );
                          return artist ? artist.name : "";
                        })
                        .join(", ")}`
                    : item?.artist_collaborators_name?.length > 0
                    ? item?.artist_collaborators_name?.join(", ")
                    : (item?.artist_collaborators_id ?? [])
                        .map((id: string) => {
                          const artist = users.find(
                            (user: any) => user._id === id
                          );
                          return artist ? artist.name : "";
                        })
                        .join(", ")}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            container
            xs={2}
            gap="1rem"
            flexWrap={"nowrap"}
            justifyContent="flex-end"
            alignItems={"center"}
          >
            <Grid item container xs={4}></Grid>
            {item.user_id === userInfo?._id &&
              localStorage.getItem("APP_GATEWAY_ACCESS_TOKEN") && (
                <Grid item container xs={4}>
                  <ShoppingCart
                    style={styleForSvg}
                    onClick={e => {
                      e.stopPropagation();
                      handleAddToCart({
                        music_id: item._id,
                        album_id: item.album_id || "",
                      });
                    }}
                  />
                </Grid>
              )}

            {item.user_id && (
              <Grid item container xs={4}>
                {isLiked ? (
                  <img
                    src={redHeart}
                    style={styleForSvg}
                    onClick={e => {
                      e.stopPropagation();
                      unLikeHandler(item._id);
                    }}
                  />
                ) : (
                  <img
                    src={emptyHeart}
                    style={styleForSvg}
                    onClick={e => {
                      e.stopPropagation();
                      likeHandler({ music_id: item._id });
                    }}
                  />
                )}
              </Grid>
            )}

            {item.user_id === userInfo?._id &&
              localStorage.getItem("APP_GATEWAY_ACCESS_TOKEN") && (
                <>
                  <Grid item container xs={4}>
                    {isInAnyOtherPlaylist || item.visibility === "PRIVATE" ? (
                      <GrayPlaylist style={styleForSvg} />
                    ) : (
                      <img
                        src={FilterMusic}
                        style={styleForSvg}
                        onClick={e => {
                          e.stopPropagation();
                          handleOpenMusicStore(e, { music_id: item._id }, item);
                        }}
                      />
                    )}
                  </Grid>
                  <Grid item container xs={4}>
                    <img
                      src={MoreSquare}
                      style={styleForSvg}
                      onClick={e => {
                        e.stopPropagation();
                        handleEditAndDeleteOpen(e, item, true);
                      }}
                    />
                  </Grid>
                </>
              )}
          </Grid>
        </Grid>
      </React.Fragment>
    );
  };

  const handleSuccessClose = () => {
    setSuccessOpen(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      await AlbumsContext?.GetAllAlbumsByUser();
      await playListContext?.GetAllPlayList();
    };
    fetchData();
  }, []);

  return (
    <>
      <div>
        {props.visible && props.albumId != null && (
          <>
            {AlbumsContext?.GetAlbumLoading ? (
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                sx={{ height: "100%" }}
              >
                <CircularProgress
                  sx={{
                    filter: "drop-shadow(0px 0px 6px #FA6B05)",
                    width: "30px !important",
                    height: "30px !important",
                    color: "#FA6B05",
                    m: 2,
                  }}
                />
              </Grid>
            ) : (
              <div>
                {AlbumsContext?.Albums &&
                  [...AlbumsContext?.Albums.albumData]
                    .reverse()
                    .filter(item => item._id === props.albumId)
                    .map((albumItem, index, albumArray) => {
                      const isLastAlbum = index === albumArray.length - 1;

                      return (
                        <React.Fragment key={index}>
                          {albumDetailsCardJsx(
                            albumItem,
                            AlbumsContext?.ArtistUsers,
                            !isLastAlbum
                          )}
                          <Grid container direction="column">
                            <div>
                              <DragDropContext
                                onDragEnd={result =>
                                  onDragEnd(result, albumItem)
                                }
                              >
                                <Droppable droppableId={props.albumId}>
                                  {provided => (
                                    <div
                                      {...provided.droppableProps}
                                      ref={provided.innerRef}
                                    >
                                      {musicItems.map(
                                        (song: any, songIndex: any) => (
                                          <Draggable
                                            key={song._id}
                                            draggableId={song._id}
                                            index={songIndex}
                                          >
                                            {provided => (
                                              <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                              >
                                                {songDetailsCardJsx(
                                                  song,
                                                  AlbumsContext?.Users,
                                                  songIndex ===
                                                    musicItems.length - 1
                                                )}
                                              </div>
                                            )}
                                          </Draggable>
                                        )
                                      )}
                                      {provided.placeholder}
                                    </div>
                                  )}
                                </Droppable>
                              </DragDropContext>
                            </div>
                          </Grid>
                        </React.Fragment>
                      );
                    })}
              </div>
            )}
          </>
        )}

        {!props.visible && (
          <div>
            {AlbumsContext?.GetAlbumLoading ? (
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                sx={{ height: "100%" }}
              >
                <CircularProgress
                  sx={{
                    filter: "drop-shadow(0px 0px 6px #FA6B05)",
                    width: "30px !important",
                    height: "30px !important",
                    color: "#FA6B05",
                    m: 2,
                  }}
                />
              </Grid>
            ) : (
              <>
                {organizedAlbums.map((album, index) => {
                  if (album.album_id !== null) {
                    const albumData = AlbumsContext?.Albums.albumData.find(
                      (item: any) => item._id === album.album_id
                    );
                    if (albumData) {
                      return (
                        <Accordion
                          key={album.album_id}
                          className="custom-accordion"
                          style={{ color: "#fff", backgroundColor: "#000000" }}
                          expanded={expandedAlbumId === album.album_id}
                          onChange={handleAccordionChange(album.album_id)}
                        >
                          <AccordionSummary
                            sx={{
                              borderBottom: "none",
                              padding: 0,
                              margin: 0,
                              backgroundColor: "#000000",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            {albumDetailsCardJsx(
                              albumData,
                              AlbumsContext?.ArtistUsers,
                              false,
                              album.songs[0],
                              expandedAlbumId === album.album_id,
                              Array.isArray(album.songs) ? album.songs : []
                            )}
                          </AccordionSummary>
                          <AccordionDetails
                            sx={{
                              borderBottom: "none",
                              margin: 0,
                              padding: 0,
                              backgroundColor: "#000000",
                            }}
                          >
                            <Grid container direction="column">
                              {album.songs.map((song: any, songIndex: any) => {
                                const uniqueSongId = `filterMusic-${songIndex}`;
                                return songDetailsCardJsx(
                                  song,
                                  AlbumsContext?.ArtistUsers,
                                  false,
                                  false,
                                  uniqueSongId
                                );
                              })}
                            </Grid>
                          </AccordionDetails>
                        </Accordion>
                      );
                    }
                  } else {
                    // Display individual songs without an album_id
                    return (
                      <div>
                        {album.songs.map((song: any) => {
                          const canBeOpen = Boolean(anchorEl);
                          const uniqueSongId = canBeOpen
                            ? `popper_${song?._id}`
                            : undefined;
                          return (
                            <div key={`individual_${song.id}`}>
                              {songDetailsCardJsx(
                                song,
                                AlbumsContext?.ArtistUsers,
                                false,
                                true,
                                uniqueSongId
                              )}
                            </div>
                          );
                        })}
                      </div>
                    );
                  }
                })}
              </>
            )}
          </div>
        )}
      </div>
      <DialogPopup
        closeDialog={closeEditAlbumDialog}
        dialogOpen={editAlbumDialogFlag}
        dialogTitle={"Edit Album"}
      >
        <EditMusicForm
          formDataState={{
            name: editAlbumFormData.name,
            price: editAlbumFormData.price,
            visibility: editAlbumFormData.visibility,
            album_cover_image: editAlbumFormData?.album_cover_image,
            album_id: editAlbumFormData._id,
            catalog_number: editAlbumFormData.catalog_number,
            isrc_code: editAlbumFormData.isrc_code,
            genres: editAlbumFormData.genres,
            release_date: editAlbumFormData.release_date,
            artist_collaborators_id: editAlbumFormData.artist_collaborators_id,
            artist_collaborators_name:
              editAlbumFormData.artist_collaborators_name,
            label_collaborators_id: editAlbumFormData.label_collaborators_id,
            label_collaborators_name:
              editAlbumFormData.label_collaborators_name,
            mark_all_songs_public: editAlbumFormData.mark_all_songs_public,
          }}
          closeDialog={closeEditAlbumDialog}
        />
      </DialogPopup>
      <DialogPopup
        closeDialog={closeEditSongDialog}
        dialogOpen={editSongDialogFlag}
        dialogTitle={"Edit Song"}
      >
        <EditSingleMusicForm
          formDataState={{
            ...editSongFormData,
          }}
          closeDialog={closeEditSongDialog}
        />
      </DialogPopup>
      <DialogPopup
        closeDialog={closeMusicDialog}
        dialogOpen={dialogMusicOpen}
        dialogTitle="Upload Music"
        unsavedChanges={unsavedChanges}
        setUnsavedChanges={setUnsavedChanges}
        disableIconButton={disableIconButton}
      >
        <UploadMusicForm
          closeDialog={closeMusicDialog}
          fromUploadMusic={false}
          albumId={props.albumId}
          labelData={props.labelData}
          setUnsavedChanges={setUnsavedChanges}
          setDisableIconButton={setDisableIconButton}
        />
      </DialogPopup>

      <PopperComponents
        popperProps={{
          sx: {
            maxWidth: "200px",
            mt: "1rem",
            zIndex: 9999,
            transition: "unset !important",
          },
        }}
        anchorEl={anchorEl}
        placement="bottom-end"
        id={uniqueItemId}
        open={openPopper}
        setOpen={setOpenPopper}
      >
        {playlistCardJsx(itemData, specificItemID)}
      </PopperComponents>

      <PopperComponents
        popperProps={{
          sx: {
            maxWidth: "130px",
            zIndex: 9999,
            transition: "unset !important",
          },
        }}
        anchorEl={anchorEl}
        placement="bottom-end"
        id={uniqueItemId}
        open={openMoreOption}
        setOpen={setOpenMoreOption}
      >
        {moreOptionCardJsx(itemData, isSong)}
      </PopperComponents>

      <Snackbar
        open={isSuccessOpen}
        autoHideDuration={6000}
        onClose={handleSuccessClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleSuccessClose}
          severity="success"
        >
          Added to Playlist
        </MuiAlert>
      </Snackbar>
    </>
  );
};

export default ManageSongListing;

import { Box, Typography } from "@mui/material";
import NavbarLayout from "components/layout/NavbarWrapper";
import WaveBackgroundLayout from "components/layout/WaveBackgroundLayout";
import { WaveRightBox, WaveLeftBox } from "components/layout/WaveBox";
import HomepageHero from "components/other/HomepageHero";
import MadeForCreators from "components/other/MadeForCreators";
import Roadmap from "components/other/Roadmap";
import VibeBuilderBox from "components/other/VibeBuilderBox";
import { BlueCardWrapper } from "components/uiCard/Cards";
import React from "react";

type Props = {};

const LandingPage = (props: Props) => {
  return (
    <React.Fragment>
    <WaveRightBox />
      <WaveBackgroundLayout twoWave oneWave={false}>
        <Box p={["1rem", "2rem", "4rem", "5rem"]} pt={["7rem", "6rem", null, null, null]}>
          <HomepageHero />

          {/* <Box p={['20px', '40px', '50px', '70px', '70px']}  />
          <VibeBuilderBox /> */}
          <Box p={['20px', '40px', '50px', '70px', '70px']} />
          <MadeForCreators />
          <Box p={['20px', '40px', '50px', '70px', '70px']} />
          {/* <Roadmap /> */}
        </Box>
      </WaveBackgroundLayout>
    </React.Fragment>
  );
};

export default LandingPage;

import { styled, useTheme } from "@mui/material/styles";
import { Box } from "@mui/material";
import wave1svg from "assets/svg/wave1.svg";
import wave2svg from "assets/svg/wave2.svg";
import wave3svg from "assets/svg/wave3.svg";

export const WaveLeftBox = styled(Box)(({ theme }) => ({
    backgroundImage: `url(${wave1svg})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "unset",
    backgroundSize: "contain",
    position: "absolute",
    width: "100vw",
    height: "100vh",
    top: "10%"
  }));

export const WaveRightBox = styled(Box)(({ theme }) => ({
    backgroundImage: `url(${wave2svg})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "right",
    backgroundSize: "contain",
    position: "absolute",
    width: "100vw",
    height: "100vh",
    top: "10%",
    right: "0px",
  }));
import axios from 'axios';

export const createRazorpayOrder = async (amount: number, currency: string) => {
    const response = await axios.post('/api/payment/razorpay/order', { amount, currency });
    return response.data;
};

export const verifyRazorpayPayment = async (data: any) => {
    const response = await axios.post('/api/payment/razorpay/verify', data);
    return response.data;
};

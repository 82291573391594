import {
    Avatar,
    CircularProgress,
    Grid,
    Box,
    useMediaQuery,
    Typography,useTheme
  } from '@mui/material';
  import { PauseCircle, PlayArrow, PlayCircle, ExpandMore, Edit, Album } from '@mui/icons-material';
  import React, { useEffect, useState } from 'react';
  import { ReactComponent as CloseSquare } from 'assets/svg/closeSquare.svg';
  import { AuthContext } from 'context/AuthContext';
  import HttpTransferService from 'services/httptransfer';
  import { TrackUploadContext } from 'context/TrackUploadContext';
  import { MusicPlayerContext } from 'context/MusicPlayerContext';
  import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
  import { Link } from 'react-router-dom';
  import { useParams } from 'react-router-dom';
  import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
  
  type Props = {
  };
  
  const httptransfer = new HttpTransferService();
  const PlaylistSongsListing = (props: Props) => {
  //const PlayList = React.useContext(PlayListContext);
  const userInfoString = localStorage.getItem('userInfo');
  const userInfo = userInfoString ? JSON.parse(userInfoString) : null;
  const trackContext = React.useContext(TrackUploadContext);
  const [currentPlayingItemId, setCurrentPlayingItemId] = useState<string | null>(null);
  const [hoveredSongId, setHoveredSongId] = useState<string | null>(null);
  const [selectedPlaylist, setSelectedPlaylist] = useState<any>({});

  const authcontext = React.useContext(AuthContext)!;
  const musicPlayerContext = React.useContext(MusicPlayerContext);
  const { playlistId } = useParams();
  const theme = useTheme();
  const phoneScreenBreakpoint = useMediaQuery(theme.breakpoints.down('sm'));

  const handleSelected = (item: any) => {
    if (currentPlayingItemId === item._id) {
      // If the clicked item is the currently playing one, toggle play/pause
      if (musicPlayerContext) {
        if (musicPlayerContext.IsPlaying) {
          musicPlayerContext.Pause();
        } else {
          musicPlayerContext.Play();
        }
      }
    } else {
        // If music is stopped or not started, start playing the selected song
      const successCallback = (res: any) => {
      const trackToGiveToPlayer = {
        id: item._id,
        trackAudioUrl: res.data.musics[0].music_mp3_audio_file.file_download_url,
        trackImageUrl: item.music_cover_image.file_download_url,
        trackName: item.name,
      };
      musicPlayerContext!.openPlayer(trackToGiveToPlayer, playlistSongs);
      setCurrentPlayingItemId(item._id);
    };
  
    const errorCallback = (err: any) => {
      console.log('err', err);
    };
  
    if (localStorage.getItem('APP_GATEWAY_ACCESS_TOKEN')) {
      const inputJson = {
        user_id: authcontext?.users ? [authcontext?.users._id] : [userInfo._id],
        mp3_play: true,
        music_id: [item._id],
      };
  
      httptransfer.musicQuery(inputJson, successCallback, errorCallback);
    }else {
      showConfirmationDialog("play");
    }
  }
}

const showConfirmationDialog = (condition: string) => {
  window.ShowInfoDialog(
    `Please login from the top right corner to ${condition}`
  );
};

  const deletePlaylistHandler = (playListId: string, musicId: string) => {
    setIsLoading(true);
    const playlistMusicId = selectedPlaylist?.musics.find(
      (music: any) => music.music_id === musicId
    )?.playlist_music_id;

    const successCallback = (res: any) => {
      setIsLoading(false);
      setPlaylistSongs((prevSongs) => prevSongs.filter((song) => song._id !== musicId));
      trackContext?.GetAllAlbumsByUser();
    };

    const errorCallback = (err: any) => {
      setIsLoading(false);
      console.log('err', err);
    };

    httptransfer.deleteSongFromPlaylist(playListId, playlistMusicId, successCallback, errorCallback);
  };

  const handleMouseEnter = (songId: string) => {
    setHoveredSongId(songId);
  };

  const handleMouseLeave = () => {
    setHoveredSongId(null);
  };
  
    const playlistDetailsCardJsx = (
      item: any,
      selectedPlaylistId: any
    ) => {
      return (
        <React.Fragment key={item._id}>
          <Grid
            container
            sx={{
              minWidth: '550px',
              borderBottom: '1px solid #636B82',
              backgroundColor: 'black',
              p: ['9px', '9.5px 20px 9.5px 20px'],
              cursor: 'pointer',
              '&:hover': {
                backgroundColor: '#ffffff1f',
              },
            }}
            justifyContent='space-between'
          >
            <Grid
              item
              container
              
              xs={10}
              justifyContent='flex-start'
              alignItems={'center'}
            >
              {(hoveredSongId === item._id && item.user_id === userInfo?._id && (localStorage.getItem('APP_GATEWAY_ACCESS_TOKEN'))) && (
              <DragIndicatorIcon />
              )} 
              {item.music_cover_image &&
              <>
              <div
                  onMouseEnter={() => handleMouseEnter(item._id)}
                  onMouseLeave={handleMouseLeave}
                  style={{ position: 'relative' }}
                >
               <Avatar
                    src={item.music_cover_image.file_download_url}
                    sx={{
                      width: '60px',
                      height: '60px',
                      borderRadius: '4px',
                      position: 'relative'
                    }}
                  ></Avatar>
                  {(hoveredSongId === item._id) && <Box
                  onClick={() => handleSelected(item)}
                    id='play-icon'
                    sx={{
                      width: '60px',
                      height: '60px',
                      borderRadius: '4px',
                      backgroundColor: '#636b825e',
                      opacity: '1',
                      // transition: "all 0.2s ease-in-out",
                      position: 'absolute',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      [theme.breakpoints.down('sm')]: {
                        position: 'absolute',
                        top: 0,
                      },
                      top: 0,
                      left: 0,
                      transition: 'opacity 0.2s ease-in-out',
                    }}
                  >
                    {musicPlayerContext?.IsPlaying &&
                      musicPlayerContext?.currentTrack?.id == item._id ? (
                      <PauseCircle onClick={musicPlayerContext?.TogglePlay} />
                    ) : (
                      <PlayCircle onClick={musicPlayerContext?.TogglePlay} />
                    )}
                  </Box>}
                  </div>
                  </>
                  }
              <Grid
                item
                container
                xs={8}
                direction={'column'}
                justifyContent='flex-start'
                alignItems='flex-start'
                flexWrap='nowrap'
              >
                <Grid
                  item
                  xs={12}
                  container
                  gap='0.5rem'
                  alignItems='center'
                  flexWrap={'nowrap'}
                  sx={{ ml: '10px' }}
                >
                  <Link to={`/playlist/${item._id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                  <Typography
                    sx={{ fontSize: ['12px', '16px'], fontWeight: '700', alignItems: "center"}}>
                    {item.name} 
                  </Typography>
                  </Link>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              container
              xs={2}
              gap='1rem'
              flexWrap={'nowrap'}
              justifyContent='flex-end'
              alignItems={'center'}
            >  
            {(item.user_id === userInfo?._id && (localStorage.getItem('APP_GATEWAY_ACCESS_TOKEN'))) &&
            <>
              <Grid item container xs={4}>
                <CloseSquare
                  onClick={(e) => {
                    e.stopPropagation();
                    window.ShowConfirmationDialog(
                      'Do you want to remove this song from playlist?',
                      () => deletePlaylistHandler(selectedPlaylistId, item._id)
                    );
                  }}
                />
              </Grid>
              </>
              }
            </Grid>
          </Grid>
        </React.Fragment>
      )
    };
  
    const [playlistSongs, setPlaylistSongs] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState(true);

    const errorCallback = (err: any) => {
      console.log("err", err);
      setIsLoading(false);
    };
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        if (trackContext) {
          await trackContext.GetAllAlbumsByUser();
        }
      } catch (error) {
        console.error('Error fetching user albums:', error);
      }
    };

    const fetchPlaylistSongs = async () => {
      try {
        let playlistMusicData = authcontext?.Playlist?._id ? authcontext?.Playlist : trackContext?.PlayListData.find((playlist: any) => playlist._id === playlistId);
        console.log("playlistMusicData", playlistMusicData);
        const musicSuccessCallback = (res: any) => {
          if (playlistMusicData && res.data && res.data.musics) {
            const musicIdOrder = playlistMusicData.musics.map((music: any) => music.music_id);
            const orderedSongs = musicIdOrder.map((musicId: string) => {
              const foundMusic = res.data.musics.find((music: any) => music._id === musicId);
              return foundMusic ? foundMusic : null;
            });
            setPlaylistSongs(orderedSongs.filter((music: any) => music !== null));
          } else {
            console.error('Invalid data received in musicSuccessCallback');
          }
        };
        setIsLoading(true);
        setSelectedPlaylist(playlistMusicData);
        let musicIds = playlistMusicData.musics.map((selectedMusic: any) => selectedMusic.music_id);
        await httptransfer.musicQuery({ music_id: musicIds }, musicSuccessCallback, errorCallback);
        }catch (error) {
        console.error('Error fetching playlist songs:', error);
      }
    };    

    const fetchData = async () => {
      await fetchUserData();
      await fetchPlaylistSongs();
      setIsLoading(false);
    };

    fetchData();
  }, [authcontext?.Playlist]);

    const onDragEnd = (result: any) => {
      if (!result.destination) {
        return;
      }

      const items = Array.from(playlistSongs);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);
      // Update the state with the reordered songs
      setPlaylistSongs(items);
      const successCallback = (res: any) => {
        setIsLoading(false);
        console.log('success', res.data);
      };
  
      const errorCallback = (err: any) => {
        console.log('err', err);
      };
     setIsLoading(true);
      const musicIds = items
        .filter(item => item._id)
        .map(item => item._id);
      httptransfer.updatePlaylist(selectedPlaylist?._id, {"musics": musicIds}, successCallback, errorCallback)
    };

  const renderDragAndDropUI = () => {
    if (localStorage.getItem('APP_GATEWAY_ACCESS_TOKEN')) {
      return (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="playlist">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {playlistSongs &&
                  playlistSongs
                    .filter((item: any) => item._id)
                    .map((item: any, index: any) => (
                      <Draggable key={item._id} draggableId={item._id} index={index}>
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <React.Fragment key={index}>
                              {playlistDetailsCardJsx(item, selectedPlaylist?._id)}
                            </React.Fragment>
                          </div>
                        )}
                      </Draggable>
                    ))}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      );
    } else {
      // Render the initial listing for others
      return (
        <>
          {playlistSongs &&
            playlistSongs
              .filter((item: any) => item._id)
              .map((item: any, index: any) => (
                <React.Fragment key={index}>
                  {playlistDetailsCardJsx(item, selectedPlaylist?._id)}
                </React.Fragment>
              ))}
        </>
      );
    }
  };
  
  return (
    <>
      {trackContext?.GetAlbumLoading || isLoading ? (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{ height: "100%" }}
        >
          <CircularProgress
            sx={{
              filter: "drop-shadow(0px 0px 6px #FA6B05)",
              width: "30px !important",
              height: "30px !important",
              color: "#FA6B05",
              m: 2,
            }}
          />
        </Grid>
      ) : (
        <div>{renderDragAndDropUI()}</div>
      )}
    </>
  );
  };
  
  export default PlaylistSongsListing;
  
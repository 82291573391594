import React, { useContext } from 'react';
import { createRazorpayOrder, verifyRazorpayPayment } from 'services/RazorpayService';
import { AuthContext } from 'context/AuthContext';
import { Button } from '@mui/material';
import { ReactComponent as AddSquare } from 'assets/svg/add-square.svg';

interface RazorpayCheckoutProps {
    items: { name: string, price: number, type: string, id: string }[];
    currency: string;
}

const RazorpayCheckout: React.FC<RazorpayCheckoutProps> = ({ items, currency }) => {
    const authContext = useContext(AuthContext);
    const user = authContext?.User;

    const handlePayment = async () => {
        try {
            const amount = items.reduce((total, item) => total + item.price * 100 * 82, 0);
            const order = await createRazorpayOrder(amount, currency); // Dynamic amount and currency
            const options = {
                key: process.env.REACT_APP_RAZORPAY_KEY_ID,
                amount: order.amount,
                currency: currency,
                name: 'Engage Music',
                description: 'Transaction',
                order_id: order.id,
                handler: async (response: any) => {
                    const data = {
                        razorpay_order_id: response.razorpay_order_id,
                        razorpay_payment_id: response.razorpay_payment_id,
                        razorpay_signature: response.razorpay_signature,
                    };
                    const result = await verifyRazorpayPayment(data);
                    if (result.success) {
                        alert('Payment Successful!');
                    } else {
                        alert('Payment verification failed!');
                    }
                },
                prefill: {
                    name: user?.name,
                    email: user?.email,
                },
                theme: {
                    color: '#F37254',
                },
            };
            const rzp1 = new (window as any).Razorpay(options);
            rzp1.open();
            rzp1.on('payment.failed', (response: any) => {
                alert(response.error.description);
            });
        } catch (error) {
            console.error('Error initiating payment:', error);
        }
    };

    return (
        <Button variant="text" color="inherit" onClick={handlePayment}>
            <AddSquare style={{ marginRight: '0.4rem' }} /> Pay with Razorpay
        </Button>
    );
};

export default RazorpayCheckout;

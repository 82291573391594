import { AuthContext } from 'context/AuthContext';
import { userType } from 'model/userType';
import constant from 'constants/constant';
import React, { useContext, useState, useEffect } from 'react';
import ArtistProfile from './Artist/ArtistProfile';
import LabelProfile from './Label/LabelProfile';
import UserProfile from './User/UserProfile';
import HttpTransferService from 'services/httptransfer';

const httptransfer = new HttpTransferService();

const ProfileWrapper = () => {
  const [userTypes, setUserTypes] = useState(null);
  const authcontext = useContext(AuthContext)!;

  useEffect(() => {
    const loadUserProfile = async () => {
      try {
        if (!authcontext?.users) {
          const userSuccessCallback = (response: any) => {
            localStorage.setItem("specificUser", response.data.users[0]._id);
            setUserTypes(response.data.users[0]?.type);
          };
          const userErrorCallback = (error: any) => {
            console.log(error);
          };
          const currentPathname = window.location.pathname;
          const parts = currentPathname.split('/');
          const username = parts[2];
  
          await httptransfer.userQuery({ username: [username] }, userSuccessCallback, userErrorCallback);
        }
      } catch (error) {
        console.error(error);
      }
    };
  
    loadUserProfile();
  }, [authcontext?.userTypeFromAPI]);  

  if (authcontext?.userTypeFromAPI || userTypes) {
    const userTypeToRender = authcontext?.userTypeFromAPI || userTypes;

    if (userTypeToRender === userType.ARTIST) {
      return <ArtistProfile />;
    }
    if (userTypeToRender === userType.USER) {
      return <UserProfile />;
    }
    if (userTypeToRender === userType.LABEL) {
      return <LabelProfile />;
    }
  }

  return null;
};

export default ProfileWrapper;

import { Grid, Box } from '@mui/material'
import WaveBackgroundLayout from 'components/layout/WaveBackgroundLayout'
import ArtistAnalytics from 'components/other/ArtistAnalytics'
import BackdropLoading from 'components/other/BackdropLoading'
import ProfileDescription from 'components/other/ProfileDescription'
import ProfileHero from 'components/other/ProfileHero'
import ProfileListWrapper from 'components/other/ProfileListWrapper'
import ProfileMiscellaneousView from 'components/other/ProfileMiscellaneousView'
import { AuthContext } from 'context/AuthContext'
import ManageSongListing from 'components/other/ManageSongListing'
import PlayListListing from 'components/other/PlayListListing'
import React from 'react'

type Props = {}

const ArtistProfile = (props: Props) => {
  const userInfo = React.useContext(AuthContext)?.User

  const tabListingData = 
  [
    {
      viewName: "Music",
      view: (
        <Box overflow="auto" maxHeight="500px">
          <ManageSongListing visible={false} albumId="" labelData={[]} />
        </Box>
      )
    },
    {
      viewName: "Playlist",
      view: <PlayListListing></PlayListListing>
    }
  ]


  return (
    <WaveBackgroundLayout oneWave={false} twoWave={false}>
      <Grid container color='white' p={["1rem", "2rem", "4rem", "5rem"]} pt={["7rem", "6rem", null, null, null]}>
        <Grid height='fit-content' item container xs={12} sm={12} md={7} lg={7}><ProfileHero /></Grid>
        <Grid pl={[0, 4]} height='fit-content' item container xs={12} sm={12} md={5} lg={5}><ProfileDescription /></Grid>
        <Grid mt={['1rem', '3.4rem']} height='fit-content' item container xs={12} sm={12} md={7} lg={7}><ProfileListWrapper tabs={tabListingData} /></Grid>
        <Grid mt={['1rem', '3.4rem']} pl={[0, 4]} height='fit-content' item container xs={12} sm={12} md={5} lg={5}><ArtistAnalytics /></Grid>
      </Grid>
    </WaveBackgroundLayout>
  )
}

export default ArtistProfile
import { Delete } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import { MusicStoreContext } from "context/MusicStoreContext";
import React, { useEffect } from "react";
import { TrackUploadContext } from "context/TrackUploadContext";
import IndeterminateCheckBoxOutlinedIcon from "@mui/icons-material/IndeterminateCheckBoxOutlined";
import { DarkButtonWithIcon } from "components/controls/Buttons";
import RazorpayCheckout from "components/customisedComponents/RazorpayCheckout";

type Props = {};

const CartListing = (props: Props) => {
  const musicStoreCtx = React.useContext(MusicStoreContext);

  const handleRemoveFromCart = (params: any) => {
    musicStoreCtx && musicStoreCtx.removeFromCart(params);
  };

  const handlePayment = async () => {
    musicStoreCtx && (await musicStoreCtx.Payment());
  };

  console.log("cart ", musicStoreCtx?.cart);

  const musicCartDetailCardJsx = (item: any) => {
    return (
      <React.Fragment>
        <ListItem
          alignItems="flex-start"
          secondaryAction={
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Typography
                sx={{ color: "white" }}
                variant="body2"
                color="text.primary"
              >
                ${item.price}
              </Typography>
              <IconButton
                onClick={() => handleRemoveFromCart({ music_id: item._id })}
                sx={{ color: "white" }}
                edge="end"
                aria-label="delete"
              >
                <IndeterminateCheckBoxOutlinedIcon />
              </IconButton>
            </Box>
          }
        >
          <ListItemAvatar>
            <Avatar
              alt="Remy Sharp"
              variant="rounded"
              src={item?.music_cover_image?.file_download_url}
            />
          </ListItemAvatar>
          <ListItemText
            secondaryTypographyProps={{
              color: "white",
              fontSize: "0.8rem",
            }}
            sx={{ mt: "0.6rem" }}
            primary={item.name}
            //secondary={item.belongsTo}
          />
        </ListItem>
        <Divider
          variant="inset"
          component="div"
          sx={{ borderColor: "#636B82", m: 0, mt: "0.5rem" }}
        />
      </React.Fragment>
    );
  };

  const albumCartDetailCardJsx = (item: any) => {
    return (
      <React.Fragment>
        <ListItem
          alignItems="flex-start"
          secondaryAction={
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Typography
                sx={{ color: "white" }}
                variant="body2"
                color="text.primary"
              >
                ${item.price}
              </Typography>
              <IconButton
                onClick={() =>
                  handleRemoveFromCart({ album_id: item._id || "" })
                }
                sx={{ color: "white" }}
                edge="end"
                aria-label="delete"
              >
                <IndeterminateCheckBoxOutlinedIcon />
              </IconButton>
            </Box>
          }
        >
          <ListItemAvatar>
            <Avatar
              alt="Remy Sharp"
              variant="rounded"
              src={item.album_cover_image.file_download_url}
            />
          </ListItemAvatar>
          <ListItemText
            secondaryTypographyProps={{
              color: "white",
              fontSize: "0.8rem",
            }}
            sx={{ mt: "0.6rem" }}
            primary={item.name}
            //secondary={item.belongsTo}
          />
        </ListItem>
        <Divider
          variant="inset"
          component="div"
          sx={{ borderColor: "#636B82", m: 0, mt: "0.5rem" }}
        />
      </React.Fragment>
    );
  };

  //elements for Razorpay
  const currency = "INR"; // Assuming INR, modify if needed

  return musicStoreCtx?.Albums?.albumData.length === 0 &&
    musicStoreCtx?.musics?.musicData.length === 0 ? (
    <React.Fragment>
      <Grid container alignItems={"center"} justifyContent={"center"} gap={2}>
        <Grid item xs={12} textAlign={"center"}>
          <Typography variant="h6">My Cart</Typography>
        </Grid>
        <Grid item xs={12} textAlign={"center"}>
          <Typography variant="body2" color="white">
            Cart is empty
          </Typography>
        </Grid>
      </Grid>
    </React.Fragment>
  ) : (
    <React.Fragment>
      <Grid container alignItems={"center"} justifyContent={"center"}>
        <Grid item xs={12} textAlign={"center"}>
          <Typography variant="h6">My Cart</Typography>
        </Grid>

        <Grid item xs={12} textAlign={"center"}>
          <List sx={{ width: "100%", color: "white" }}>
            {musicStoreCtx?.musics &&
              musicStoreCtx?.musics?.musicData.map((song: any) => {
                return musicCartDetailCardJsx(song);
              })}

            {musicStoreCtx?.Albums &&
              musicStoreCtx?.Albums?.albumData.map((album: any) => {
                return albumCartDetailCardJsx(album);
              })}
          </List>
        </Grid>

        {/* Added the RazorpayCheckout with the dynamic ammount and currency set to India's current currency */}
        <Grid item xs={12}>
          <RazorpayCheckout
            items={[
              ...musicStoreCtx?.musics?.musicData,
              ...musicStoreCtx?.Albums?.albumData,
            ]}
            currency={currency}
          />
        </Grid>

        {/* <ListItem
              sx={{ py: 0, mt: 1 }}
              alignItems="flex-start"
              secondaryAction={
                <Typography
                  sx={{ color: "white" }}
                  variant="body2"
                  color="text.primary"
                >
                  ${musicStoreCtx?.cartAmount}
                </Typography>
              }
            >
              <ListItemText
                primaryTypographyProps={{
                  sx: {
                    fontSize: "1rem",
                    color: "#C5CFDB",
                  },
                }}
                primary="Sub Total"
              />
            </ListItem>
            <ListItem
              sx={{ py: 0 }}
              alignItems="flex-start"
              secondaryAction={
                <Typography
                  sx={{ color: "white" }}
                  variant="body2"
                  color="text.primary"
                >
                  ${musicStoreCtx && ((18 / 100) * musicStoreCtx?.cartAmount).toFixed(2)}
                </Typography>
              }
            >
              <ListItemText
                primaryTypographyProps={{
                  sx: {
                    fontSize: "1rem",
                    color: "#C5CFDB",
                  },
                }}
                primary={"Tax(GST-18%)"}
              />
            </ListItem>
            <ListItem
              sx={{ py: 0, mb: 1 }}
              alignItems="flex-start"
              secondaryAction={
                <Typography
                  sx={{ color: "white" }}
                  variant="body2"
                  fontWeight={"600"}
                  color="text.primary"
                >
                  ${musicStoreCtx && (musicStoreCtx?.cartAmount + (18 / 100) * musicStoreCtx?.cartAmount).toFixed(2)}
                </Typography>
              }
            >
              <ListItemText
                primaryTypographyProps={{
                  sx: {
                    fontSize: "1rem",
                    color: "#C5CFDB",
                  },
                }}
                primary="Grand Total"
              />
            </ListItem>
            <Divider
              variant="inset"
              component="div"
              sx={{ borderColor: "#636B82", m: 0, mt: "0.5rem" }}
            /> */}

        <Grid item xs={12}>
          <DarkButtonWithIcon
            // onClick={async()=>{
            //   await handlePayment()
            // }}
            disabled={true}
            sx={{ width: "100%", mt: 1 }}
          >
            CHECKOUT
          </DarkButtonWithIcon>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default CartListing;
